// 引入二次封装的axios
// import request from "../request";
import request from "../loginMenu";

// 获取机构列表
export function getOrgList(data) {
  return request({
    url: '/sdbackend/apanageOrg/list',
    method: 'post',
    data
  })
}

// 获取机构人员列表
export function getOrgUserList(data) {
  return request({
    // url:'/mtBackend/matBackendUser/list',
    url: '/sdbackend/apanageBackendUser/list',
    method: 'post',
    data
  })
}

// 添加机构人员
export function saveOrgUser(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/save',
    method: 'post',
    data
  })
}

// 获取机构已添加的普通用户
export function ordinaryUser(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/ordinaryUser',
    method: 'post',
    data

  })
}
// 删除当前用户
export function deleteOrgUser(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/delete/' + data,
    method: 'get'
  })
}
//停用用户
export function disableStatus(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/disableStatus',
    method: 'post',
    data
  })
}

//启用用户  
export function enableStatus(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/enableStatus',
    method: 'post',
    data
  })
}
// 获取下拉角色列表
export function orgRoleList(data) {
  return request({
    url: '/sdbackend/apanageBackendRole/list',
    method: 'post',
    data
  })
}
//获取用户详情
export function getUserInfo(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/info/' + data,
    method: 'get'
  })
}

// //获取用户管理账号配置列表 
// export function getUserProdList(data){
//     return request({
//         url:'',
//         method:'post',
//         data
//     })
// }


// 修改当前用户信息
export function editUserInfo(data) {
  return request({
    url: '/sdbackend/apanageBackendUser/update',
    method: 'post',
    data
  })
}
//-----------------------------------------
// 获取用户组
export function getUserGroup() {
  return request({
    // url: 'http://192.168.50.248:8701/apanageOrg/wxbList',
    url: '/sdbackend/apanageOrg/wxbList',
    method: 'get'
  })
}


//-------------------------------------
//获取省份
export function provinceList(data) {
  return request({
    url: "/system/sysRegion/provinceList",
    method: 'post',
    data
  })
}
//获取城市
export function cityList(data) {
  return request({
    url: "/system/sysRegion/cityList",
    method: 'post',
    data
  })
}
//获取地区
export function districtList(data) {
  return request({
    url: "/system/sysRegion/districtList",
    method: 'post',
    data
  })
}
//获取所有微信名称
export function queryAllName(nickname) {
  return request({
    url: "/system/WechatUserinfo/queryAllName",
    method: 'post',
    data: {
      nickname: nickname,
      page: 1,
      limit: 30
    }
  })
}
