<template>
  <div class="page">
    <div class="organ-top">
      <div class="page-header">用户管理</div>
      <div class="xzbg">
        <div class="xzbgbtn" @click="addOrgUser(agentId)">新增用户</div>
      </div>
    </div>
    <div class="organ-content">
      <div class="organ-content-center">
        <el-tree :data="dataTree" node-key="id" :expand-on-click-node="false" :default-expanded-keys="[16303]" :props="defaultProps" highlight-current
                 @node-click="handleNodeClick" :current-node-key="16303" v-loading='orgLoading' style='height:100%'></el-tree>
        <!-- <el-scrollbar style="height: 100%; weight:100%">
          <ul>
            <li id="more" @click="switchTag(item, inx)" v-for="(item, inx) in agentList" :key="inx" :class="num == inx ? 'item' : ''">
              <el-tooltip effect="dark" :content="item.name" placement="right" :disabled='item.name.length<=10'>
                <div>
                  {{ item.name }}
                </div>
              </el-tooltip>
            </li>
          </ul>
        </el-scrollbar> -->
      </div>
      <div class="organ-content-right">
        <div class="organ-content-right-top">
          <el-input class="m160" v-model="sech.value1" clearable placeholder="姓名/账号"></el-input>
          <el-select class="m160" v-model="sech.value3" clearable placeholder="账号状态">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker  style="margin: 10px 10px 10px 0" v-model="sech.value2" type="datetimerange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker> -->
          <el-date-picker v-model="sech.value2" type="date" placeholder="最后登录时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
          <div class="search-btn" @click="getOrgUserList">
            搜 索
          </div>
          <div class="reset-btn" @click="reset">
            重 置
          </div>
        </div>
        <div class="organ-content-right-bottom">

          <el-table class="m-t" stripe :data="tableData" :header-cell-style="{'text-align': 'center',background: '#F6F7FA',color: '#333',}" v-loading='loading'
                    :cell-style="{ 'text-align': 'center' }" style="width: 100%;text-align: center;border: 1px solid #e4e6ec;border-bottom: none;">
            <el-table-column prop="name" label="姓名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="phoneNum" label="账号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="orgName" label="所属用户组" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="lastLoginTime" label="最后登录时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="statusName" label="账号状态">
              <template slot-scope="scope">
                <span :class="scope.row.statusName == '启用' ? 'start' : 'stop'"></span>已{{ scope.row.statusName }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="operation">
                  <span @click="updateUserStatus(scope.row, 1)" v-if="scope.row.status == 0" class="Enable">
                    <i class="el-icon-video-play"></i>
                    启用</span>
                  <span @click="updateUserStatus(scope.row, 0)" v-if="scope.row.status == 1" class="Deactivate">
                    <i class="el-icon-video-pause"></i>
                    停用</span>
                  <span @click="toDetails(scope.row)" class="details">
                    <i class="el-icon-tickets"></i>
                    详情</span>
                  <span @click="open(scope.row)" class="delete">
                    <i class="el-icon-delete"></i>
                    删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background :current-page="page.currentPage"
                           :page-sizes="[15, 30, 50]" :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
            </el-pagination>
          </div> -->
          <Pagination :pageNumber="page.currentPage" :pageSize="page.pageSize" :total="page.total" @SizeChange="handleSizeChange"
                      @CurrentChange="handleCurrentChange"></Pagination>
        </div>
      </div>
    </div>
    <el-dialog title="新增用户" :visible.sync="dialogVisible" width="30%">
      <div class="dialog-box">
        <div class="dialog-box-box">
          <div class="right">
            <div class="right-content">
              <div>
                <div class="label"><span>*</span>姓名</div>
                <el-input v-model="addPersonnel.value2"></el-input>
              </div>
              <div>
                <div class="label"><span>*</span>手机号(账号)</div>
                <el-input v-model="addPersonnel.value3"></el-input>
              </div>
              <div>
                <div class="label"><span>*</span>密码</div>
                <el-input v-model="addPersonnel.passWord" show-password></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm(agentId)" v-loading='addUserLoading'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  provinceList,
  cityList,
  districtList,
  queryAllName, //获取微信用户
  //--------------------------------------
  getOrgList, //获取机构列表
  getOrgUserList, //获取机构人员列表
  saveOrgUser, //添加机构人员
  ordinaryUser, //获取已添加人员
  deleteOrgUser, //删除人员
  disableStatus, //停用用户
  enableStatus, //启用用户
  getUserGroup,//获取用户组
} from "../../api/account/index"; 
// import _ from 'lodash'
import Pagination from "@/components/pagination.vue";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      dataTree: [],//机构树
      defaultProps: {//机构树配置
        children: 'child',
        label: 'name'
      },
      loading: false,
      orgLoading: false,
      addUserLoading:false,
      orgCount: 20,
      isAdmin: 1,//是否是管理员
      treeHighlight: true,
      switchAll: 1,
      roleValue: "", //选择角色
      options: [], //存储选角色
      regionData: [],
      props: {
        label: "name",
        children: "zones",
        isLeaf: "leaf",
      },
      currentId: null,
      currentCode: '',
      cityId: null,
      districtId: null,
      mechanismName: "",
      sech: {
        value1: "",
        value2: "",
        value3: null,
        value4: null,
        // value5: '',
      },
      agentId: 16303, //机构id
      options1: [
      ],
      options2: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "停用",
        },
      ],
      options3: [], //存储微信用户
      options4: [],
      addPersonnel: {
        //手动添加人员信息
        value1: "",
        value2: "",
        value3: "",
        passWord: ''
      },
      isOrgAdmin: 1,
      num: -1,//机构列表选中样式
      agentList: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [{}],
      dialogVisible: false,
      checkbox: [], //存储选中的已添加用户
      matList: [], //获取已添加用户
    };
  },
  created() {
  },
  mounted() {
    // this.getOrgList();
    this.getOrgUserList();
    this.getUserGroup()
  },
  methods: {
    reset() {
      this.sech = {
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      }
      this.page.currentPage = 1
      this.getOrgUserList()
    },
    // 添加机构人员按钮
    addOrgUser(agentId) {
      if (agentId == null) {
        this.$message.warning("请先选择机构,再重新添加");
        return;
      }
      this.dialogVisible = true;
      this.addUserLoading = false
      this.checkbox = [];
      this.addPersonnel = {
        value1: "",
        value2: "",
        value3: "",
        passWord:"",
      };
      this.roleValue = null
      this.isAdmin = 1
      // this.ordinaryUser(this.agentId);
    },
    // 添加机构人员提交按钮
    async submitForm(agentId) {
      if (agentId == null) {
        this.$message.warning("请先选择机构,再重新添加");
        this.dialogVisible = false;
        return;
      }
      let data = this.checkbox.map((item) => {
        return {
          name: item.name,
          wechatNameId: item.wechatNameId,
          phoneNum: item.phoneNum,
          orgUserId: item.id,//人员id
          orgId: item.orgId,//机构id
          role: this.roleValue,//角色
          isProdAdmin: this.isAdmin,//是否是产品管理员
        };
      });
      let addManul = {
        // wechatNameId: this.addPersonnel.value1,
        name: this.addPersonnel.value2,
        phoneNum: this.addPersonnel.value3,
        passWord:this.addPersonnel.passWord,
        orgId: this.agentId,
        role:0,
        isProdAdmin: 0,
      };
      if (
        // addManul.wechatNameId === "" &&
        addManul.name === "" &&
        addManul.phoneNum === ""
      ) {
        data;
      } else {
        data.push(addManul);
      }
      if (
        this.checkbox.length === 0 &&
        // addManul.wechatNameId === "" &&
        addManul.name === "" &&
        addManul.phoneNum === ""
      ) {
        this.dialogVisible = false;
      } else {
        this.addUserLoading = true
        let res = await saveOrgUser(data);
        if (res.data.code == 200) {
          this.addUserLoading = false
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.dialogVisible = false;
          this.getOrgUserList();
        }else{
          this.$message({
            type: "warning",
            message: "添加出错了,请稍后重试!",
          });
        }
      }
    },
    // 获取机构已添加用户
    async ordinaryUser(agentId) {
      if (agentId == -1) {
        this.$message.warning("请先选择机构,再重新添加");
        this.dialogVisible = false;
        return;
      }
      let res = await ordinaryUser({ orgId: agentId }); //已添加用户
      this.matList = res.data.data;
    },
    // 更新用户状态
    async updateUserStatus(row, type) {
      let data = {
        orgUserId: row.id,
        status: type,
        // userProdStatus: type,
      };
      if (data.status == 1) {
        //启用
        let res = await enableStatus(data);
        if (res.data.code == 200) {
          this.$message.success("启用成功");
          this.getOrgUserList();
        }
      } else if (data.status == 0) {
        //停用
        let res = await disableStatus(data);
        if (res.data.code == 200) {
          this.$message.success("停用成功");
          this.getOrgUserList();
        }
      }
    },
    //删除机构产品
    open(row) {
      this.$confirm("是否确定删除该人员？", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteOrgUser([row.id]).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getOrgUserList();
          }
        });
      });
    },
    // 点击机构名称
    switchTag(item, inx) {
      this.num = inx; //机构名称样式
      this.agentId = item.id; //机构id
      this.getOrgUserList();
    },
    toDetails(row) {
      this.$router.push({
        path: "/account/detail",
        query: {
          id: row.id,
          // orgId: this.agentId
        },
      });
    },
    // 获取机构人员列表
    async getOrgUserList() {
      this.loading = true
      this.tableData = []
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        // province: this.currentId,
        // district: this.districtId,
        // city: this.cityId,
        // userTypes: [1, 2],//用户类型，半角逗号分割：0 机构管理员、 1 产品管理员、2 产品普通用户、3 机构普通用户
        orgId: this.agentId||16303,
        status: this.sech.value3, //状态
        // roles: this.sech.value4 ? this.sech.value4 : '', //角色id
        // wechatNameId: this.sech.value1?this.sech.value1:"", //微信id
        // phoneNum: this.sech.value1?this.sech.value1:"", //电话
        name: this.sech.value1 ? this.sech.value1 : "", //模糊查询参数(姓名/微信昵称/手机)
        lastLoginTime: this.sech.value2 ? this.sech.value2 : '', //最后登录日期
      };
      let res = await getOrgUserList(data);
      if (res.data.code == 200) {
        if (res.data.data.list) {
          this.tableData = res.data.data.list
          this.page.total = res.data.data.totalCount;
          this.loading = false
        }
      } else {
        this.tableData = [],
          this.page.total = 0
      }
    },
    //机构列表
    async getOrgList(orgCount) {
      let data = {
        name: this.mechanismName,
        page: 1,
        // limit:20
      };
      if (orgCount) {
        data.limit = orgCount
      } else {
        data.limit = 20
      }
      if (this.currentId != null) {
        data.province = this.currentId;
      }
      if (this.cityId != null) {
        data.city = this.cityId;
      }
      if (this.districtId != null) {
        data.district = this.districtId;
      }
      let res = await getOrgList(data);
      this.agentList = res.data.data.list.map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      });
    },
    // 获取用户组
    async getUserGroup() {
      this.orgLoading = true
      let res = await getUserGroup()
      if (res.data.code == 200) {
        this.dataTree = [res.data.data]
        this.agentList = res.data.data.child.map((item) => {
          return {
            name: item.name,
            id: item.id,
          };
        });
        this.orgLoading = false
      }
    },
    handleNodeClick(data, node) {
      console.log(data, node);
      // this.num = inx; //机构名称样式
      this.agentId = data.id; //机构id
      this.getOrgUserList();
    },
    // 分页列表函数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.currentPage = 1;
      this.getOrgUserList()
    },
    // 分页页码函数
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getOrgUserList()
    },
  },
};
</script>

<style lang='scss' scoped>
.page {
  .addressAll {
    text-indent: 11px;
    font-size: 16px;
    color: #606266;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
  }
  .addressAll1 {
    text-indent: 11px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(270deg, #40dee7 0%, #1884ff 100%);
  }
  .organ-top {
    display: flex;
    justify-content: space-between;
    // padding-bottom: 20px;
    .xzbg {
      // width: 100%;
      height: 36px;
      margin-bottom: 20px;
      .xzbgbtn {
        width: auto;
        padding: 8px 14px 8px 14px;
        background: #f58030;
        color: #ffffff;
        border-radius: 4px;
        float: right;
        cursor: pointer;
      }
    }
    span {
      font-size: 16px;

      font-weight: bold;
      color: #0c2b4a;
    }
  }

  .organ-content {
    display: flex;
    .organ-content-center {
      height: 730px;
      // overflow: auto;
      min-width: 230px;
      background: #ffffff;
      border-radius: 4px;
      margin-right: 20px;
      padding: 20px 9px;
      // margin-left: 2px;
      position: relative;
      ul {
        // margin-top: 60px;
        padding: 0 10px;
        height: 700px;
        // overflow: auto;

        li {
          width: 180px;
          font-size: 14px;
          height: 38px;
          line-height: 38px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0c2b4a;
          // margin: 10px 0;
          text-align: center;
          cursor: pointer;
          // overflow: hidden;
        }

        .item {
          background: linear-gradient(
            270deg,
            rgb(248, 157, 85) 0%,
            rgb(244, 99, 11) 100%
          );
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }

    .organ-content-right {
      flex: 1;
      width: 0;
      border-radius: 10px;

      .organ-content-right-top {
        position: relative;
        padding: 10px 23px;
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .reset-btn {
          position: absolute;
          top: 18px;
          right: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 38px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e1e1e1;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-left: 16px;
          cursor: pointer;
        }
        .search-btn {
          position: absolute;
          top: 18px;
          right: 100px;
          width: auto;
          padding: 8px 14px 8px 14px;
          background: #f58030;
          color: #ffffff;
          border-radius: 4px;
          cursor: pointer;
        }
        .m160 {
          width: 165px;
          margin-top: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 8px;
        }
      }

      .organ-content-right-bottom {
        background: #ffffff;
        border-radius: 4px;
        padding: 24px;
      }
    }
  }

  .dialog-box {
    .dialog-box-box {
      width: 100%;
      background: #ffffff;
      // border: 1px solid #e3e5ec;
      .right {
        .right-title {
          font-size: 14px;
          font-weight: 800;
          color: #737a94;
          padding: 10px 20px;
          background: #f8fafb;
        }
        .right-content {
          padding: 0px 20px 10px;
          .label {
            font-size: 14px;
            font-weight: 550;
            color: #2b3f57;
            padding: 10px 0;
            span {
              color: #ff5f5f;
            }
          }
        }
      }

      .dialog-box-box-btn {
        display: flex;
        padding: 20px 30px;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        font-weight: 550;
        color: #2b3f57;
        .switch-role {
          width: 246px;
          margin-left: 20px;
        }
        .role-span {
          color: #ff5f5f;
        }
      }
    }
  }

  #more {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /*设置显示的行数*/
    line-clamp: 1; /*设置显示的行数*/
    -webkit-box-orient: vertical;
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  height: 100%;
}
//表格斑马纹颜色
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f6f7fa;
}
.operation {
  display: flex;
  align-items: center;
}

.operation span {
  margin-right: 5px;
}

.operation span > i {
  margin-right: 4px;
}
.Enable {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #267dff;
  display: flex;
  align-items: center;
  padding: 5px;
}

.Deactivate {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #267dff;
  display: flex;
  align-items: center;
  padding: 5px;
}

.details {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #267dff;
  display: flex;
  align-items: center;
  padding: 5px;
}

.delete {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #ff4d4f;
  display: flex;
  align-items: center;
  padding: 5px;
}

.el-table__body tr > td .details:hover {
  background: #267dff;
  border-radius: 8px;
  color: #ffffff;
  padding: 5px;
}

.el-table__body tr > td .Deactivate:hover {
  background: #267dff;
  border-radius: 8px;
  color: #ffffff;
  padding: 5px;
}

.el-table__body tr > td .Enable:hover {
  background: #267dff;
  border-radius: 8px;
  color: #ffffff;
  padding: 5px;
}

.el-table__body tr > td .delete:hover {
  background: #ff4d4f;
  border-radius: 8px;
  color: #ffffff;
  padding: 5px;
}

.start {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 5px;
  background: #51d7b1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.stop {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 5px;
  background: #ff4d4f;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
</style>