<template>
        <el-pagination  class="table-el"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
        :pager-count='count'
       
      >
      </el-pagination>
      <!-- layout="total, sizes, prev, pager, next, jumper" -->
</template>

<script>
export default {
    props:{
        pageSizes:{ // 下来时可选择页码范围
            type:Array,
            default(){
                return [10, 20, 30, 40,50]
            }
        },
        count:{  //超过多少页折叠
            type:Number,
            default:5  
        }, 
        layout:{ //类型
             type:String,
             default:"total, sizes, prev, pager, next, jumper"
        },
        pageNumber:{  //页码
            type:Number,
            default:1
        },
        pageSize:{  //每页条数
           type:Number,
            default:10, 
        },
        total:{  //总数
           type:Number,
            default:0, 
        }
    },
methods:{
    handleSizeChange(val) {
      window.scrollTo(0, 0);
      this.$emit('SizeChange',val)
    },
    handleCurrentChange(val) {
        window.scrollTo(0, 0);
        this.$emit('CurrentChange',val)
        
    },
}
}
</script>

<style lang="scss" scoped>
.table-el{
display: flex;
// align-items: center;
//   float: right;
justify-content: flex-end;
  margin-bottom:16px ;
  margin-top: 16px;
  


// ::v-deep  .el-pagination.is-background .el-pager li:not(.disabled).active{
//   background-color: #FFF1E8;
// color: #F58030;
//     border: 1px solid #F58030;
// }
// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{color:#f70}
// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{color:#fff}
// ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{background-color:#f70;color:#fff}
// ::v-deep .el-pagination__sizes .el-input .el-input__inner:hover{border-color:#f70}
// ::v-deep .el-select .el-input__inner:focus{border-color:#f70}
// ::v-deep .el-pager li:hover{color:#f70}
// ::v-deep .el-pagination button:hover{color:#f70}
// ::v-depp .el-select .el-input__inner:focus{border-color:#f70}
// ::v-deep .el-pager li:hover{color:#f70}
// ::v-deep .el-pagination__sizes .el-input .el-input__inner:hover{border-color:#f70}
// ::v-deep .el-cascader .el-input .el-input__inner:focus,.el-cascader .el-input.is-focus .el-input__inner{border-color:#f70}

// el-input输入时设置边框颜色
::v-deep .el-input__inner:focus {border: #F58030 1px solid;}
::v-deep .el-input .el-input__inner:focus,.el-cascader .el-input.is-focus .el-input__inner{border-color:#f70}
::v-deep .el-pagination__total{
        height: 32px;
        line-height: 32px;
    }
::v-deep .el-pagination.is-background{
        height: 32px;
        padding: 0px;
    }
    ::v-deep .el-pagination__sizes{
        // position: absolute;
        // right: 15px;
        // height: 32px;
        .el-select .el-input.is-focus .el-input__inner{
            border-color: #F58030;
        }
        
        .el-input__inner{
            height: 32px !important;;
            font-size: 14px;
            font-weight: 500;
            color: #737A94;
        }
        .el-input__inner:hover{
            border-color: #F58030;
        }
        
    }
    ::v-deep .el-pagination__editor.el-input .el-input__inner{
            height: 32px !important;;
        }
    ::v-deep .number{
        height: 32px !important;
        min-width: 32px !important;
        background-color: #FFFFFF !important;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
        border-left:10
        // margin: 0 1px;
        // box-sizing: border-box;
    }
    ::v-deep .number:hover{
        color: #F58030 !important;
    }
    ::v-deep .number.active{
        background-color: #FFF1E8 !important;
        border-color:#F58030 !important;
        color: #F58030 !important;
    }
    ::v-deep .btn-next{
         height: 32px;
        width: 32px;
        background: #FFFFFF !important;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
        // margin-right: 110px;
    }
    ::v-deep .btn-prev{
         height: 32px;
        width: 32px;
        background: #FFFFFF !important;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
    }
    ::v-deep .el-icon.more.btn-quicknext.el-icon-more{
        height: 32px;
        width: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
    }
    ::v-deep .el-icon.more.btn-quickprev.el-icon-more{
         height: 32px;
        width: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
    }
    ::v-deep .el-icon.more.btn-quickprev.el-icon-d-arrow-left{
         height: 32px;
        width: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
    }
    ::v-deep .el-icon.more.btn-quicknext.el-icon-d-arrow-right{
         height: 32px;
        width: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #9FA6BC;
        line-height: 32px;
    }
    ::v-deep .el-pager li.active + li {
    border-left: 1;
  }
}
</style>